import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MaterialDesignContent, useSnackbar } from "notistack";
import { error, info, primary, warning } from "src/theme/palette";
import { icon } from "src/utils/loadSvg";

const StyledCloseButton = styled(IconButton)(() => ({
  color: "#fff",
  padding: "4px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

// Close Button Component
export const SnackbarCloseButton = ({ snackbarId }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <StyledCloseButton
      aria-label="close"
      onClick={() => closeSnackbar(snackbarId)}
    >
      {icon("x-icon", 20, 20, "#fff")}
    </StyledCloseButton>
  );
};

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  () => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: primary.darker,
      color: "#fff",
      "& svg": {
        // This targets the icon directly
        color: primary.main,
      },
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: error.darker,
      color: "#fff",
      "& svg": {
        color: error.lighter,
      },
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: warning.darker,
      color: "#fff",
      "& svg": {
        color: warning.lighter,
      },
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: info.darker,
      color: "#fff",
      "& svg": {
        color: info.lighter,
      },
    },
  })
);
