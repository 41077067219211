import { grey, primary } from "src/theme/palette";
import { WorkerAvatarProps, areWorkerAvatarPropsEqual } from "./propsValidator";
import React, { memo } from "react";

const WorkerAvatar: React.FC<WorkerAvatarProps> = ({
  firstLetter = " ",
  width = 40,
  height = 40,
  backgroundColor = grey[200],
}) => {
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: "50%",
        backgroundColor: backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: primary.contrastText,
      }}
    >
      {firstLetter.length === 0 || firstLetter === undefined
        ? " "
        : firstLetter}
    </div>
  );
};

export default memo(WorkerAvatar, areWorkerAvatarPropsEqual);
