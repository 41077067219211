import { useEffect, useState, useCallback } from "react";
import { version } from "src/v";

const VERSION_CHECK_INTERVAL = 15 * 60 * 1000;

export const useVersionCheck = () => {
  const [hasUpdate, setHasUpdate] = useState(false);

  const checkForUpdates = useCallback(async () => {
    try {
      const response = await fetch("/v.json", { cache: "no-cache" });
      if (!response.ok) {
        console.error("Error checking for updates:", response.statusText);
        return;
      }

      const remoteVersion = await response.json();

      if (remoteVersion.version !== version) {
        setHasUpdate(true);
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  }, []);

  useEffect(() => {
    checkForUpdates();

    const intervalId = setInterval(checkForUpdates, VERSION_CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [checkForUpdates]);

  return {
    hasUpdate,
    resetUpdate: () => setHasUpdate(false),
    checkForUpdates,
  };
};

export default useVersionCheck;
