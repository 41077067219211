import { primary } from "src/theme/palette";

export const styles = {
  container: {
    position: "fixed",
    bottom: 16,
    right: 16,
    display: "flex",
    gap: 2,
    zIndex: 1000,
    alignItems: "center",
    "@media (max-width: 550px)": {
      flexDirection: "column-reverse",
      alignItems: "flex-end",
    },
  },
  weekControl: {
    display: "flex",
    alignItems: "center",
    bgcolor: "background.paper",
    boxShadow: 2,
    borderRadius: "8px",
    px: 2,
    height: 40,
  },
  weekText: {
    mx: 2,
    whiteSpace: "nowrap",
  },
  toggleButtonGroup: {
    bgcolor: "background.paper",
    boxShadow: 2,
    borderRadius: "8px",
    overflow: "hidden",
    height: 40,
    "& .MuiToggleButton-root": {
      border: "none",
      borderRadius: 0,
      px: 3,
      typography: "body2",
      color: "text.secondary",
      "&.Mui-selected": {
        bgcolor: "primary.main",
        color: "primary.contrastText",
        "&:hover": {
          bgcolor: "primary.dark",
        },
      },
      "&:hover": {
        bgcolor: "action.hover",
      },
    },
  },
  showHideControlsButton: {
    bgcolor: "background.paper",
    boxShadow: 2,
    borderRadius: "8px",
    width: 40,
    height: 40,
    flexShrink: 0,
    "&:hover": {
      bgcolor: "background.paper",
    },
  },
  controlsWrapper: {
    alignItems: "center",
    gap: 2,
    transition: "transform 0.3s ease-in-out",
    "@media (max-width: 550px)": {
      flexDirection: "column",
      alignItems: "flex-end",
      marginBottom: 1,
    },
  },
  versionContainer: {
    padding: "0px 24px 20px 24px",
  },
  versionHeader: {
    color: primary.contrastText,
    padding: "16px 16px 16px 24px",
  },
} as const;
