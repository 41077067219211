import {
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from "@mui/material";
import { GroupType } from "src/types/group";
import { UnitType } from "src/types/unit";
import { MenuProps } from "../shiftPlan/calendar/requirements/styles";
import { DepartmentType } from "src/types/department";
import { wrapperStyles, selectStyles } from "./styles";
import { OrganizationType } from "src/types/organization";

interface GenericSelectProps<
  T extends GroupType | UnitType | DepartmentType | string | OrganizationType,
> {
  selectedValue: number | null | string;
  onChange: (value: number) => void;
  items: T[];
  label?: string;
  allItemsLabel?: string;
  sx?: {
    wrapper?: object;
    select?: object;
  };
  fullWidth?: boolean;
  allOptionsToShow?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const GenericSelect = <
  T extends UnitType | GroupType | DepartmentType | string | OrganizationType,
>({
  selectedValue,
  onChange,
  items,
  label,
  allItemsLabel = "Všetky",
  sx = {
    wrapper: {},
    select: {},
  },
  fullWidth = false,
  allOptionsToShow = false,
  required = false,
  disabled = false,
}: GenericSelectProps<T>) => {
  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const value = event.target.value;
    onChange(typeof value === "string" ? parseInt(value) : value);
  };

  return (
    <Box
      sx={{
        ...wrapperStyles(fullWidth),
        ...sx.wrapper,
      }}
    >
      <FormControl fullWidth={fullWidth} required={required}>
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-label`}
          id={`${label}-select`}
          value={selectedValue?.toString() || ""}
          onChange={handleChange}
          label={label}
          MenuProps={MenuProps}
          sx={{
            ...selectStyles,
            ...sx.select,
          }}
          disabled={disabled}
          required={required}
        >
          {allOptionsToShow && (
            <MenuItem value="0">{`${allItemsLabel} ${
              label?.toLowerCase() || ""
            }`}</MenuItem>
          )}
          {items.map((item) =>
            typeof item === "string" ? (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ) : (
              <MenuItem key={item.id} value={item.id.toString()}>
                {item.name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default GenericSelect;
