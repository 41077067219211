// VersionChecker.jsx
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useVersionCheck } from "src/hooks/version/useVersionCheck";
import { styles } from "./styles";
import { Divider } from "@mui/material";

const VersionChecker = () => {
  const { hasUpdate, resetUpdate } = useVersionCheck();

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleClose = () => {
    resetUpdate();
  };

  return (
    <Dialog
      open={hasUpdate}
      onClose={handleClose}
      aria-labelledby="update-dialog-title"
      aria-describedby="update-dialog-description"
      sx={styles.versionContainer}
    >
      <DialogTitle id="update-dialog-title" sx={styles.versionHeader}>
        Aktualizácia aplikácie
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="update-dialog-description">
          Je dostupná nová verzia aplikácie, odporúčame obnoviť stránku
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Obnovím stránku manuálne neskôr
        </Button>
        <Button
          onClick={handleRefresh}
          color="primary"
          variant="contained"
          autoFocus
        >
          Obnoviť stránku teraz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VersionChecker;
