import {
  ErrorResponseWithMessage,
  ErrorResponseWithArray,
  ErrorDetail,
} from "src/types/error";

type ErrorResponse = ErrorResponseWithMessage | ErrorResponseWithArray;

export const messageFormatter = (message: unknown): string => {
  const error = message as ErrorResponse;

  // Check if message has a nested array in response.data.message
  if (
    error?.response?.data &&
    "message" in error.response.data &&
    Array.isArray(error.response.data.message)
  ) {
    return error.response.data.message
      .map((err: ErrorDetail) => `${err.error}`)
      .join(", ");
  }

  // Check if message has a direct array in response.data
  if (Array.isArray(error?.response?.data)) {
    return error.response.data
      .map((err: ErrorDetail) => `${err.error}`)
      .join(", ");
  }

  // Check for regular message object
  if (
    error?.response?.data &&
    "message" in error.response.data &&
    typeof error.response.data.message === "string"
  ) {
    return error.response.data.message;
  }

  return "Vyskytla se chyba";
};

export const firstLetterFormatter = (name: string): string => {
  return name && name.length > 0
    ? name.split("")[0][0] + name.split(" ")[1][0]
    : " ";
};
