export const api = {
  getAccessToken: (): string => {
    return window.localStorage.getItem("accessToken") || "";
  },
  setAccessToken: (accessToken: string): void => {
    window.localStorage.setItem("accessToken", accessToken);
  },
  clearAccessToken: (): void => {
    window.localStorage.removeItem("accessToken");
  },
  setOrganizationId: (organizationId: number): void => {
    window.localStorage.setItem("organizationId", organizationId.toString());
  },
  getOrganizationId: (): number | undefined => {
    const id = window.localStorage.getItem("organizationId");
    return id ? parseInt(id) : undefined;
  },
  clearOrganizationId: (): void => {
    window.localStorage.removeItem("organizationId");
  },
};
