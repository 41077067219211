import { alpha } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

// ----------------------------------------------------------------------

// SETUP COLORS
export const grey = {
  0: "#FFFFFF",
  100: "#F3F6F7",
  200: "#E6EAEB",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
} as const;

export const primary = {
  lighter: "#DAFFEB", // states-focus
  light: "#9DFEC9",
  main: "#23DD91",
  dark: "#1AB174",
  darker: "#003A42", // contrastText
  contrastText: "#003A42",
  states: {
    hover: "#F2FDF8",
    selected: "#23DD91",
    focus: "#DAFFEB",
    focusVisible: "#DAFFEB",
    outlinedBorder: "#23DD9180",
  },
} as const;

export const secondary = {
  lighter: "#F4F6F8",
  light: "#98A8AA",
  main: "#758284",
  dark: "#545E5F",
  darker: "#212B36",
  contrastText: "#003A42",
} as const;

export const info = {
  lighter: "#F8FBFF",
  light: "#EEF6FF",
  main: "#2362BD",
  dark: "#C8DCFB",
  darker: "#2362BD",
  contrastText: "#2362BD",
} as const;

export const success = {
  lighter: "#F2FFF5",
  light: "#E8FFED",
  main: "#23DD91",
  dark: "#B8F7C5",
  darker: "#118857",
  contrastText: "#118857",
} as const;

export const warning = {
  lighter: "#FFFBEF",
  light: "#FFF5D7",
  main: "#F09102",
  dark: "#FFECB2",
  darker: "#CB9702",
  contrastText: "#CB9702",
} as const;

export const error = {
  lighter: "#FFF9F9",
  light: "#FFF3F3",
  main: "#F85042",
  dark: "#FFD9D8",
  darker: "#EA4335",
  contrastText: "#EA4335",
} as const;

export const common = {
  black: "#000000",
  white: "#FFFFFF",
} as const;

export const requirement = {
  day: {
    icon: "#CB9702",
    background: "#FFF5D7",
    hoverBackground: "#FFF5D7",
    hoverBorderBackground: "#F09102",
  },
  night: {
    icon: "#6A39FB",
    background: "#F2F0FF",
    hoverBackground: "#F2F0FF",
    hoverBorderBackground: "#6A39FB",
  },
  morning: {
    icon: "#4285F4",
    background: "#EEF6FF",
    hoverBackground: "#EEF6FF",
    hoverBorderBackground: "#4285F4",
  },
  afternoon: {
    icon: "#34A853",
    background: "#E8FFED",
    hoverBackground: "#E8FFED",
    hoverBorderBackground: "#34A853",
  },
  dayNight: {
    icon: "#B800A1",
    background: "#FFEBFA",
    hoverBackground: "#FFEBFA",
    hoverBorderBackground: "#B800A1",
  },
  warning: {
    icon: "white",
    background: "#F85042",
    hoverBackground: "#BC1E1B",
    hoverBorderBackground: "#BC1E1B",
  },
  other: {
    icon: "#23DD91",
    background: "#002E34",
    hoverBackground: "#002E34",
    hoverBorderBackground: "#002E34",
  },
} as const;

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
} as const;

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
} as const;

// ----------------------------------------------------------------------

export function palette(): PaletteOptions {
  return {
    ...base,
    mode: "light",
    text: {
      primary: grey[600],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: "#FFFFFF",
      default: grey[100],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };
}
