import { useCallback, useEffect, useState } from "react";
import Logo from "../../assets/logo.png";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import { useTheme, alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import { usePathname, useRouter } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";
import Scrollbar from "src/components/scrollbar";
import { navConfig } from "./config-navigation";
import useAuth from "src/hooks/useAuth";
import { IconButton, Tooltip } from "@mui/material";
import { secondary, error } from "src/theme/palette";
import WorkerAvatar from "src/components/worker/WorkerAvatar";
import { icon } from "src/utils/loadSvg";
import { navStyles, navItemStyles } from "../styles/styles";
import { Role } from "src/types/base";
import { useOrganizations } from "src/hooks/organization/useOrganizations";
import GenericSelect from "src/components/selects/GenericSelect";
import { api } from "src/api/api";
import { firstLetterFormatter } from "src/utils/messageFormatter";

interface NavProps {
  openNav: boolean;
  onCloseNav: () => void;
}

interface NavItemProps {
  item: {
    title: string;
    path: string;
    icon: React.ReactNode;
    roles: Role[];
  };
  isCollapsed: boolean;
  isMobile: boolean;
}

interface MenuOption {
  label: string;
  icon: string;
  color?: string;
  onClick?: string;
  path?: string;
}

const ROLE_MAPPING: Record<Role, string> = {
  [Role.SUPER_ADMIN]: "Super admin",
  [Role.ORGANIZATION_ADMIN]: "Admin organizácie",
  [Role.DEPARTMENT_MANAGER]: "Manažér oddelenia",
  [Role.WORKER]: "Pracovník",
};

const MENU_OPTIONS: MenuOption[] = [
  {
    label: "Odhlásiť sa",
    icon: "logout",
    color: error.main,
    onClick: "logout",
  },
];

const COLLAPSED_WIDTH = 72;
const EXPANDED_WIDTH = 280;
const SHORTEN_TEXT_LENGTH = 15;

const Nav = ({ openNav, onCloseNav }: NavProps) => {
  const { user, logout, organizationId, updateOrganization } = useAuth();
  const { organizations } = useOrganizations({
    fetchOrganizations: user.role === Role.SUPER_ADMIN,
  });
  const pathname = usePathname();
  const router = useRouter();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isShiftPlanPage = pathname.includes("shift-plans/");
  const [isCollapsed, setIsCollapsed] = useState(isShiftPlanPage);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setIsCollapsed(pathname.includes("shift-plans/"));
  }, [pathname, organizationId]);

  const navWidth = isCollapsed && isDesktop ? COLLAPSED_WIDTH : EXPANDED_WIDTH;
  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (option: MenuOption) => {
      if (option.onClick === "logout") {
        logout();
      } else if (option.path) {
        router.push(option.path);
      }
      handleCloseMenu();
    },
    [logout, router, handleCloseMenu]
  );

  const handleOrganizationSelect = useCallback(
    (value: number) => {
      updateOrganization(value);
      api.setOrganizationId(value);
      router.push("/shift-plans");
    },
    [router, updateOrganization]
  );

  const renderAccount = (isMobile: boolean) => (
    <Box sx={navStyles.account({ navWidth, isCollapsed, isMobile })}>
      <Box sx={navStyles.accountBox}>
        {user.role === Role.SUPER_ADMIN && (
          <Box
            sx={{
              width: "100%",
              display: isCollapsed && !isMobile ? "none" : "block",
            }}
          >
            {organizations && (
              <GenericSelect
                items={organizations || []}
                selectedValue={organizationId}
                label="Organizácia"
                onChange={(value: number) => handleOrganizationSelect(value)}
                sx={{ select: { height: 56 } }}
                fullWidth
              />
            )}
          </Box>
        )}
        <Box sx={navStyles.userInfo({ isCollapsed, isMobile })}>
          <Box>
            <WorkerAvatar
              width={40}
              height={40}
              firstLetter={firstLetterFormatter(
                `${user.firstName} ${user.lastName}`
              )}
              backgroundColor={alpha(secondary.main, 0.15)}
            />
          </Box>

          {(!isCollapsed || isMobile) && (
            <Box sx={navStyles.userBadge}>
              <Box sx={navStyles.userDetails}>
                <Tooltip
                  title={`${user.firstName} ${user.lastName}`}
                  placement="top"
                  arrow
                >
                  <Typography variant="body2" sx={navStyles.userName}>
                    {`${user.firstName} ${user.lastName}`.slice(
                      0,
                      SHORTEN_TEXT_LENGTH
                    ) +
                      (`${user.firstName} ${user.lastName}`.length >
                      SHORTEN_TEXT_LENGTH
                        ? "..."
                        : "")}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={ROLE_MAPPING[user.role as Role]}
                  placement="top"
                  arrow
                >
                  <Typography variant="body2" sx={navStyles.userName}>
                    {ROLE_MAPPING[user.role as Role].slice(0, 15) +
                      (ROLE_MAPPING[user.role as Role].length > 15
                        ? "..."
                        : "")}
                  </Typography>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  float: "right",
                  backgroundColor: "rgba(117, 130, 132, 0.08)",
                  borderRadius: 1,
                }}
              >
                <IconButton sx={navStyles.iconButton} onClick={handleOpenMenu}>
                  {icon("more", 24, 24, secondary.main)}
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    sx: navStyles.menuPaper,
                  }}
                >
                  {MENU_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => handleMenuItemClick(option)}
                      sx={navStyles.menuItem({ color: option.color })}
                    >
                      <ListItemIcon
                        sx={navStyles.menuItemIcon({ color: option.color })}
                      >
                        {icon(
                          option.icon,
                          24,
                          24,
                          option.color || secondary.main
                        )}
                      </ListItemIcon>
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );

  const renderLogo = () => (
    <Box sx={navStyles.logo}>
      <Box
        component="img"
        src={Logo}
        sx={navStyles.logoImage}
        alt="Planly logo"
      />
    </Box>
  );

  const renderMenu = (isMobile: boolean) => (
    <Stack component="nav" spacing={1} sx={navStyles.menu}>
      {navConfig.map(
        (item) =>
          item.roles.includes(user.role as Role) && (
            <NavItem
              key={item.title}
              item={item}
              isCollapsed={!isMobile && isCollapsed}
              isMobile={isMobile}
            />
          )
      )}
    </Stack>
  );

  const renderContent = (isMobile: boolean) => (
    <Scrollbar sx={navStyles.scrollbar}>
      {renderLogo()}
      {renderMenu(isMobile)}
      {renderAccount(isMobile)}
    </Scrollbar>
  );

  return (
    <Box component="nav" sx={navStyles.nav({ navWidth, isCollapsed })}>
      {isDesktop ? (
        <Box sx={navStyles.desktopNav({ navWidth, isCollapsed })}>
          {renderContent(false)}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: navStyles.drawer({ navWidth, isCollapsed }),
          }}
        >
          {renderContent(true)}
        </Drawer>
      )}
    </Box>
  );
};

const NavItem = ({ item, isCollapsed, isMobile }: NavItemProps) => {
  const pathname = usePathname();
  const active = pathname.includes(item.path);

  return (
    <ListItemButton
      component={RouterLink as React.ElementType}
      to={item.path}
      sx={navItemStyles.button({ isCollapsed, isMobile, active })}
    >
      <Box component="span" sx={navItemStyles.icon({ active })}>
        {item.icon}
      </Box>

      {(!isCollapsed || isMobile) && (
        <Box component="span" sx={navStyles.text}>
          {item.title}
        </Box>
      )}
    </ListItemButton>
  );
};

export default Nav;
