import { isEqual } from "lodash";
import { UnitType } from "src/types/unit";
import { WorkerProfileType } from "src/types/workerProfile";
import { areShiftPlanSummaryCalendarTypeEqual } from "../shiftPlan/calendar/propsValidator";
import { ShiftPlanSummaryCalendarType } from "src/types/shiftPlan";
import { CSSProperties } from "@mui/styled-engine";

export interface WorkerInfoCellProps {
  workerProfile: WorkerProfileType;
  unit?: UnitType;
  showJobDescription?: boolean;
  showUnit?: boolean;
  showHelper?: boolean;
  variant?: "dialog" | "calendar" | "units" | "groups";
  userStyles?: CSSProperties;
  userSummaryData?: ShiftPlanSummaryCalendarType;
}

export interface WorkerAvatarProps {
  firstLetter: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
}

export interface WorkerTextCellProps {
  fullName: string;
  colorText?: string;
  variant?:
    | "body1"
    | "body2"
    | "subtitle1"
    | "subtitle2"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
}

export interface WorkerActionsProps {
  onWorkerTypeSelect: (option: string) => void;
  onAddToGroup: () => void;
  shiftPlanId?: number;
}

export interface WorkerHelperIconProps {
  isPrimarySubstitution?: boolean;
  isSecondarySubstitution?: boolean;
}

export interface WorkerFormHeaderProps {
  isEditing?: boolean;
  workerProfileData?: WorkerProfileType;
  name?: string;
  workerProfileType?: string;
  type?: "units" | "groups";
}

// --------------------------------------------
//                VALIDATOR

export const arePropsWorkerInfoCellEqual = (
  prevProps: WorkerInfoCellProps,
  nextProps: WorkerInfoCellProps
) => {
  return (
    prevProps.showJobDescription === nextProps.showJobDescription &&
    isEqual(prevProps.workerProfile, nextProps.workerProfile) &&
    prevProps.variant === nextProps.variant &&
    prevProps.showUnit === nextProps.showUnit &&
    prevProps.unit?.id === nextProps.unit?.id &&
    prevProps.showHelper === nextProps.showHelper &&
    prevProps.userStyles === nextProps.userStyles &&
    prevProps.userSummaryData &&
    areShiftPlanSummaryCalendarTypeEqual(
      [prevProps.userSummaryData],
      [nextProps.userSummaryData]
    )
  );
};

export const areWorkerAvatarPropsEqual = (
  prevProps: WorkerAvatarProps,
  nextProps: WorkerAvatarProps
) => {
  return (
    prevProps.firstLetter === nextProps.firstLetter &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.backgroundColor === nextProps.backgroundColor
  );
};

export const arePropsWorkerTextCellEqual = (
  prevProps: WorkerTextCellProps,
  nextProps: WorkerTextCellProps
) => {
  return prevProps.fullName === nextProps.fullName;
};

export const arePropsWorkerActionsEqual = (
  prevProps: WorkerActionsProps,
  nextProps: WorkerActionsProps
) => {
  return (
    prevProps.shiftPlanId === nextProps.shiftPlanId &&
    prevProps.onAddToGroup === nextProps.onAddToGroup &&
    prevProps.onWorkerTypeSelect === nextProps.onWorkerTypeSelect
  );
};

export const areWorkerHelperIconPropsEqual = (
  prevProps: WorkerHelperIconProps,
  nextProps: WorkerHelperIconProps
) => {
  return (
    prevProps.isPrimarySubstitution === nextProps.isPrimarySubstitution &&
    prevProps.isSecondarySubstitution === nextProps.isSecondarySubstitution
  );
};

export const areWorkerFormHeaderPropsEqual = (
  prevProps: WorkerFormHeaderProps,
  nextProps: WorkerFormHeaderProps
) => {
  return (
    prevProps.isEditing === nextProps.isEditing &&
    prevProps.name === nextProps.name &&
    prevProps.workerProfileData === nextProps.workerProfileData &&
    prevProps.type === nextProps.type &&
    prevProps.workerProfileType === nextProps.workerProfileType
  );
};
