import { ReservedShiftKeysEnum, ShiftKeysEnum } from "src/constants";

export enum Role {
  SUPER_ADMIN = "SUPER_ADMIN",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  DEPARTMENT_MANAGER = "DEPARTMENT_MANAGER",
  WORKER = "WORKER",
}

export type DayType = {
  dayNumber: number;
  dayName: string;
};

export type MonthType = {
  id: number;
  name: string;
};

export type ShiftTypeConfig = {
  icon: string;
  backGroundColor: string;
  iconColor: string;
};

export type ShiftSpecificationCalendarDayType = {
  day: number;
  count: number;
  shiftKey: ShiftKeysEnum | ReservedShiftKeysEnum | "";
};

export type CalendarDayType = Record<number, boolean>;

export type ShiftRestrictionType = {
  shiftKey: ShiftKeysEnum | ReservedShiftKeysEnum;
  maxShifts?: number;
  minShifts?: number;
};

export enum WorkerProfileEnum {
  PRIMARY = "PRIMARY",
  PRIMARY_SUBSTITUTION = "PRIMARY_SUBSTITUTION",
  SECONDARY_SUBSTITUTION = "SECONDARY_SUBSTITUTION",
  EXTERNAL = "EXTERNAL",
}
