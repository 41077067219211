import { primary } from "src/theme/palette";
import { typography } from "src/theme/typography";

export const styles = {
  dialogPaperProps: {
    paddding: "24px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  saveButtonStyles: {
    textTransform: "none",
    fontWeight: 600,
    backgroundColor: "primary.main",
    "&:hover": {
      backgroundColor: "primary.dark",
    },
  },
  datePickerStyles: {
    "& .MuiOutlinedInput-root": {
      fontSize: typography.subtitle1,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E7ECF0",
      },
    },
    flex: 1,
    height: "56px",
    fontSize: typography.subtitle1,
  },
  selectStyles: {
    fontSize: typography.body1,
    height: "56px",
  },
  headerContainer: {
    padding: "16px 16px 16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: 500,
    color: primary.contrastText,
  },
  halfDayText: {
    fontWeight: 500,
    color: primary.contrastText,
    marginBottom: "8px",
  },
  closeButton: {
    color: "text.secondary",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  userInfoContainer: {
    display: "flex",
    alignItems: "center",
    padding: "24px",
    borderRadius: "8px",
  },
  avatar: {
    width: 56,
    height: 56,
    border: "2px solid #fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    ml: 2.5,
    flex: 1,
  },
  userName: {
    fontSize: "1.125rem",
    fontWeight: 400,
    color: primary.contrastText,
    lineHeight: 1.3,
  },
  jobDescription: {
    fontSize: "0.875rem",
    color: "text.secondary",
    mt: 0.5,
    lineHeight: 1.4,
    display: "flex",
    alignItems: "center",
  },
  unitName: {
    fontSize: "0.875rem",
    color: "text.secondary",
    mt: 0.5,
    lineHeight: 1.4,
    display: "flex",
    alignItems: "center",
    "&::before": {
      content: '""',
      display: "inline-block",
      width: "4px",
      height: "4px",
      borderRadius: "50%",
      backgroundColor: "text.secondary",
      margin: "0 8px",
    },
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3 + ITEM_PADDING_TOP,
      width: 220,
    },
  },
};
