import { useQuery } from "@tanstack/react-query";
import { organizationApi } from "src/api/organization";
import { OrganizationType } from "src/types/organization";

interface UseOrganizations {
  fetchOrganizations?: boolean;
}

export const useOrganizations = ({
  fetchOrganizations = true,
}: UseOrganizations = {}) => {
  const { data: organizations, isLoading } = useQuery<OrganizationType[]>({
    queryKey: ["organizations"],
    queryFn: async () => organizationApi.getOrganizations(),
    enabled: fetchOrganizations,
  });

  return { isLoading, organizations };
};
