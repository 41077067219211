import {
  CreateOrganizationType,
  OrganizationType,
  UpdateOrganizationType,
} from "src/types/organization";
import api from "../lib/lib";

export const organizationApi = {
  getOrganizations: async (): Promise<OrganizationType[]> => {
    return await api.get("/organizations");
  },
  getOrganization: async (
    organizationId: number
  ): Promise<OrganizationType> => {
    return await api.get(`/organizations/${organizationId}`);
  },
  createOrganization: async (
    organization: CreateOrganizationType
  ): Promise<OrganizationType> => {
    return await api.post("/organizations", organization);
  },
  updateOrganization: async (
    organizationId: number,
    organization: UpdateOrganizationType
  ): Promise<OrganizationType> => {
    return await api.put(`/organizations/${organizationId}`, organization);
  },
  deleteOrganization: async (organizationId: number): Promise<void> => {
    return await api.delete(`/organizations/${organizationId}`);
  },
};
