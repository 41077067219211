import { grey, secondary } from "src/theme/palette";
import { Role } from "src/types/base";
import { icon } from "src/utils/loadSvg";

interface NavItem {
  title: string;
  path: string;
  icon: JSX.Element;
  roles: Role[];
}

export const navConfig: NavItem[] = [
  {
    title: "Organizácie",
    path: "/organizations",
    icon: icon("organization", 24, 24, secondary.main),
    roles: [Role.SUPER_ADMIN],
  },
  {
    title: "Plány zmien",
    path: "/shift-plans",
    icon: icon("logo_icon_green", 24, 24, secondary.main),
    roles: [
      Role.SUPER_ADMIN,
      Role.ORGANIZATION_ADMIN,
      Role.DEPARTMENT_MANAGER,
      Role.WORKER,
    ],
  },
  {
    title: "Oddelenia",
    path: "/departments",
    icon: icon("department", 24, 24, secondary.main),
    roles: [Role.SUPER_ADMIN, Role.ORGANIZATION_ADMIN, Role.DEPARTMENT_MANAGER],
  },
];
