import { Theme, alpha } from "@mui/material/styles";
import { grey, primary, secondary } from "src/theme/palette";
import { typography } from "src/theme/typography";

interface StyleProps {
  navWidth?: number;
  isCollapsed?: boolean;
  isMobile?: boolean;
  active?: boolean;
  color?: string;
}

export const navStyles = {
  menuPaper: {
    width: 200,
    marginTop: -7,
  },

  text: {
    ml: 2,
    fontSize: typography.body1,
    flexGrow: 1,
  },

  nav: (props: StyleProps) => ({
    flexShrink: 0,
    width: {
      lg: props.navWidth,
    },
  }),

  menuItem: (props: StyleProps) => ({
    padding: "8px 12px",
    color: props.color,
    "&:hover": {
      backgroundColor: alpha(props.color || secondary.main, 0.08),
    },
  }),

  menuItemIcon: (props: StyleProps) => ({
    minWidth: 32,
    color: props.color,
  }),

  desktopNav: (props: StyleProps) => ({
    height: "100vh",
    position: "fixed",
    width: props.navWidth,
    transition: (theme: Theme) =>
      theme.transitions.create("width", {
        duration: theme.transitions.duration.standard,
      }),
    bgcolor: "background.paper",
    borderRight: "1px solid",
    borderColor: "divider",
    overflow: "hidden",
  }),

  drawer: (props: StyleProps) => ({
    width: props.navWidth,
    bgcolor: "background.default",
  }),

  scrollbar: {
    height: "100%",
    bgcolor: grey[100],
    "& .simplebar-content": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },

  logo: {
    p: 2.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  logoImage: {
    height: 28,
    width: "auto",
    objectFit: "contain",
  },

  menu: {
    px: 1,
    width: "100%",
    mb: 8,
  },

  account: (props: StyleProps) => ({
    position: "fixed",
    bottom: 0,
    width: props.isMobile ? 260 : props.navWidth,
  }),

  accountBox: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },

  logoutButton: {
    border: "none",
    color: primary.contrastText,
  },

  userInfo: (props: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent:
      props.isCollapsed && !props.isMobile ? "center" : "flex-start",
    padding: "4px 16px",
    borderRadius: 1,
    bgcolor:
      props.isCollapsed && !props.isMobile
        ? "transparent"
        : alpha(secondary.main, 0.15),
    gap: 2,
    height: 56,
  }),

  avatar: {
    width: 32,
    height: 32,
  },

  userDetails: {
    gap: 1,
  },

  userName: {
    color: primary.contrastText,
    whiteSpace: "nowrap",
  },

  iconButton: {
    p: 1,
  },

  userBadge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "56px",
  },
};

export const navItemStyles = {
  button: (props: StyleProps) => ({
    height: 56,
    width: props.isCollapsed ? 56 : "100%",
    borderRadius: 1,
    padding: "8px 16px",
    fontWeight: 400,
    color: primary.contrastText,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: props.isCollapsed ? "center" : "flex-start",
    ...(props.active && {
      color: primary.contrastText,
      fontWeight: 500,
      bgcolor: alpha(secondary.main, 0.12),
    }),
    ...(props.isMobile && {
      px: 2,
      "&:hover": {
        bgcolor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
      },
    }),
    ...(!props.isCollapsed && {
      px: 2,
    }),
  }),

  icon: (props: StyleProps) => ({
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: props.active ? "primary.main" : "inherit",
    "& svg": {
      width: 24,
      height: 24,
    },
  }),

  text: {
    ml: 2,
    fontSize: 14,
    flexGrow: 1,
  },
};
