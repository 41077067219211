import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { api } from "src/api/api";
import { authApi } from "src/api/auth";
import { Role } from "src/types/base";
import { UserType } from "src/types/user";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: UserType | null;
  organizationId?: number;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: UserType | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: UserType | null;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type UpdateAction = {
  type: "UPDATE";
  payload: {
    data: {
      firstName: string;
      lastName: string;
      email: string;
    };
  };
};

type UpdateActionOrganization = {
  type: "UPDATE_ORGANIZATION";
  payload: number;
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | UpdateAction
  | UpdateActionOrganization;

interface AuthContextValue extends State {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  update: (data: {
    firstName: string;
    lastName: string;
    email: string;
  }) => Promise<void>;
  updateOrganization: (organizationId: number) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State, _action: LogoutAction): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    organizationId: undefined,
  }),
  UPDATE: (state: State): State => {
    return {
      ...state,
    };
  },
  UPDATE_ORGANIZATION: (
    state: State,
    action: UpdateActionOrganization
  ): State => {
    const organizationId = action.payload;
    return {
      ...state,
      user: {
        ...state.user,
      },
      organizationId,
    };
  },
} as const;

const reducer = (state: State, action: Action): State => {
  if (action.type in handlers) {
    return handlers[action.type](state, action as never);
  }
  return state;
};

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  update: () => Promise.resolve(),
  updateOrganization: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = await authApi.me();

        if (user) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isInitialized: true,
              isAuthenticated: true,
              user: user,
            },
          });
          if (user.role === Role.SUPER_ADMIN) {
            const organizationId = api.getOrganizationId();
            if (organizationId) {
              dispatch({
                type: "UPDATE_ORGANIZATION",
                payload: organizationId,
              });
            }
          }
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isInitialized: false,
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isInitialized: false,
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const { access_token, user } = await authApi.login({ email, password });
    api.setAccessToken(access_token);
    dispatch({
      type: "LOGIN",
      payload: {
        user: user,
      },
    });
  };

  const logout = async (): Promise<void> => {
    dispatch({ type: "LOGOUT" });
    api.clearAccessToken();
    api.clearOrganizationId();
  };

  const update = async (data: {
    firstName: string;
    lastName: string;
    email: string;
  }): Promise<void> => {
    dispatch({
      type: "UPDATE",
      payload: {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        },
      },
    });
  };

  const updateOrganization = async (organizationId: number): Promise<void> => {
    dispatch({
      type: "UPDATE_ORGANIZATION",
      payload: organizationId,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        update,
        updateOrganization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
