import Holidays from "date-holidays";
import dayjs, { Dayjs } from "dayjs";
import { CalendarDayType } from "src/types/base";
import { CalendarType } from "src/types/request";

interface DateRange {
  fromDate: string;
  toDate: string;
}

export const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

export const generateDays = (month: number, year: number) => {
  const days = Array.from(
    { length: getDaysInMonth(month, year) },
    (_, i) => i + 1
  );
  return days;
};

export const isWeekend = (day: number, month: number, year: number) => {
  const date = new Date(year, month, day);
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

export const isPublicHoliday = (
  day: number,
  month: number,
  year: number,
  country: string
) => {
  const hd = new Holidays();
  hd.init(country);

  const date = new Date(year, month, day);

  const holiday = hd.isHoliday(date);

  return holiday ? true : false;
};

export const isHolidayOrWeekendDay = (
  day: number,
  month: number,
  year: number,
  country: string
) => {
  const isWeekendDay = isWeekend(day, month, year);
  const isPublicHolidayDay = isPublicHoliday(day, year, month, country);

  return isWeekendDay || isPublicHolidayDay;
};

interface DateTypeMaps {
  holidayMap: CalendarDayType;
  weekendMap: CalendarDayType;
  combinedMap: CalendarDayType;
}

export const createDateTypeMaps = (
  days: number[],
  month: number,
  year: number,
  locale: string = "SK"
): DateTypeMaps => {
  const holidayMap: CalendarDayType = {};
  const weekendMap: CalendarDayType = {};
  const combinedMap: CalendarDayType = {};

  days.forEach((day: number, index: number) => {
    const isPublicHolidayDay = isPublicHoliday(day, month, year, locale);
    const isWeekendDay = isWeekend(day, month, year);

    holidayMap[index] = isPublicHolidayDay;
    weekendMap[index] = isWeekendDay;
    combinedMap[index] = isPublicHolidayDay || isWeekendDay;
  });

  return { holidayMap, weekendMap, combinedMap };
};

export const isDateInRange = (
  dayNumber: number,
  currentMonth: number,
  currentYear: number,
  range: DateRange
): boolean => {
  // Create date for the day we're checking
  const targetDate = new Date(currentYear, currentMonth, dayNumber);
  const fromDate = new Date(range.fromDate);
  const toDate = new Date(range.toDate);

  targetDate.setHours(0, 0, 0, 0);
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(0, 0, 0, 0);

  const isInRange = targetDate >= fromDate && targetDate <= toDate;
  return isInRange;
};

export const getDisabledDates = (
  userCalendarData: CalendarType[],
  requirementAction: CalendarType
): Set<string> => {
  if (!userCalendarData || userCalendarData.length === 0) {
    return new Set<string>();
  }

  const dates = new Set<string>();
  const selectedFromDate = requirementAction.fromDate
    ? dayjs(requirementAction.fromDate)
    : null;

  // Sort calendar data by start date
  const sortedCalendarData = [...userCalendarData].sort((a, b) =>
    dayjs(a.fromDate).diff(dayjs(b.fromDate))
  );

  sortedCalendarData.forEach((calendar) => {
    // Skip the current requirement when editing
    if (requirementAction.id && calendar.id === requirementAction.id) {
      return;
    }

    const calendarStart = dayjs(calendar.fromDate);
    const calendarEnd = dayjs(calendar.toDate);

    // Disable the actual range dates
    let currentDate = calendarStart;
    while (
      currentDate.isBefore(calendarEnd) ||
      currentDate.isSame(calendarEnd, "day")
    ) {
      dates.add(convertToISODate(currentDate));
      currentDate = currentDate.add(1, "day");
    }

    // If we have a selected fromDate and it's before this range
    if (
      selectedFromDate?.isValid() &&
      selectedFromDate.isBefore(calendarStart)
    ) {
      // Disable from the end of this range onwards
      const futureLimit = dayjs().add(10, "year");
      currentDate = calendarEnd;

      while (currentDate.isBefore(futureLimit)) {
        dates.add(convertToISODate(currentDate));
        currentDate = currentDate.add(1, "day");
      }
    }

    // If we have a selected fromDate and it's after this range
    if (selectedFromDate?.isValid() && selectedFromDate.isAfter(calendarEnd)) {
      // Disable all dates from start of month to end of this range
      currentDate = calendarEnd.startOf("month");
      while (
        currentDate.isBefore(calendarEnd) ||
        currentDate.isSame(calendarEnd, "day")
      ) {
        dates.add(convertToISODate(currentDate));
        currentDate = currentDate.add(1, "day");
      }
    }
  });

  return dates;
};

export const convertToISODate = (date: Dayjs): string => {
  return dayjs(date).format("YYYY-MM-DD");
};
