import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { usePathname } from 'src/routes/hooks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { icon } from 'src/utils/loadSvg';
import { primary } from 'src/theme/palette';
import Nav from './nav';

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const pathname = usePathname();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isShiftPlanPage = pathname.includes('shift-plans/');
  const COLLAPSED_WIDTH = 72;
  const EXPANDED_WIDTH = 280;

  const sidebarWidth = isShiftPlanPage ? COLLAPSED_WIDTH : EXPANDED_WIDTH;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', position: 'relative' }}>
      {/* Nav component with higher z-index */}
      <Box sx={{ zIndex: 1200, position: 'relative' }}>
        <Nav openNav={open} onCloseNav={handleDrawerClose} />
      </Box>

      <Box
        component="main"
        sx={{
          width: {
            xs: '100%',
            lg: `calc(100% - ${sidebarWidth}px)`
          },
          position: 'relative',
          flexGrow: 1,
          transition: theme => theme.transitions.create(['width', 'margin-left'], {
            duration: theme.transitions.duration.shorter,
          }),
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          {!isDesktop && (
            <IconButton
              onClick={handleDrawerOpen}
              sx={{
                top: 16,
                left: 16,
                boxShadow: 1,
                width: 50,
                height: 50,
                backgroundColor: primary.main,
              }}
            >
              {icon('dash-icon', 50, 50, primary.contrastText)}
            </IconButton>
          )}
          
          <Box
            sx={{ 
              flexGrow: 1,
              width: '100%',
              px: isShiftPlanPage ? 0 : 0,
              py: isShiftPlanPage ? 0 : 0,
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}