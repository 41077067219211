import { SxProps } from "@mui/material";

export const inputStyles: SxProps = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "6px",
    },
  },
};

export const listItemStyles = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: "24px",
  fontSize: "16px",
  padding: "8px 16px",
};

export const listBoxStyles = {
  maxHeight: 180,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.06)",
  margin: "4px 0",
  borderRadius: "6px",
};

export const wrapperStyles = (fullWidth: boolean): SxProps => ({
  display: "flex",
  alignItems: "center",
  width: fullWidth ? "100%" : "auto",
  minWidth: "200px",
});

export const selectStyles: SxProps = {
  height: "40px",
  fontSize: "16px",
};

export const menuItemStyles: SxProps = {
  display: "flex",
  alignItems: "center",
};

export const menuItemBoxStyles: SxProps = {
  display: "flex",
  alignItems: "center",
};

export const menuItemTypographyStyles: SxProps = {
  ml: 2,
};

export const formControlStyles: SxProps = {
  width: "100%",
};

export const chipBoxStyles: SxProps = {
  display: "flex",
  flexWrap: "wrap",
  gap: 0.5,
};
