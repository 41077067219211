import { CalendarType } from "src/types/request";
import { UnitType, UnitUsers } from "src/types/unit";
import { CalendarDayType, DayType, ShiftTypeConfig } from "src/types/base";
import { isEqual } from "lodash";
import { GroupType } from "src/types/group";
import { ReservedShiftKeysEnum, ShiftKeysEnum } from "src/constants";
import { WorkerProfileType } from "src/types/workerProfile";
import { ShiftPlanSummaryCalendarType } from "src/types/shiftPlan";
import dayjs from "dayjs";
import { convertToISODate } from "src/utils/date";

export interface EmptyCellProps {
  isCompactView: boolean;
  backgroundColor: string;
  calendarData?: CalendarType;
  day?: number;
  displayDayText?: boolean;
  handleOpen?: (data: CalendarType) => void;
  handleHover?: (data: { day?: number; workerProfileUserId?: number }) => void;
}

export interface ShiftPlanCalendarProps {
  users: UnitUsers;
  units: UnitType[];
  month: number;
  year: number;
  calendarData: CalendarType[];
  addEditManuallyRequest?: (data: CalendarType) => void;
  availableKeys: (ShiftKeysEnum | ReservedShiftKeysEnum)[];
  isResultView?: boolean;
  summaryData?: ShiftPlanSummaryCalendarType[];
}

export interface ShiftPlanCalendarTableHeaderProps {
  searchTerm: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  days: DayType[];
  isHolidaysOrWeekendDay: CalendarDayType;
  hoveredDay: number | null;
  isSticky?: boolean;
  scrollXValue?: number;
}

export interface ShiftPlanCalendarUserRowProps {
  workerProfile: WorkerProfileType;
  days: DayType[];
  month: number;
  year: number;
  userCalendarData: CalendarType[];
  unitId: number;
  handleOpen?: (data: CalendarType) => void;
  handleHover?: (data: { day?: number; workerProfileUserId?: number }) => void;
  isResultView?: boolean;
  isHolidayOrWeekendDayMap: CalendarDayType;
  isHoveredWorkerProfile?: boolean;
  userSummaryData?: ShiftPlanSummaryCalendarType;
}

export interface ShiftContentProps {
  shiftConfig: ShiftTypeConfig;
  calendarData: CalendarType;
  isCompactView: boolean;
  isSpecialResultView?: boolean;
  hoveredDay?: number;
  isResultView?: boolean;
  handleOpen?: (data: CalendarType) => void;
  handleHover?: (data: { day?: number; workerProfileUserId?: number }) => void;
  unitId?: number;
}

export interface ShiftPlanUnitTableProps {
  unit: UnitType;
  workerProfiles: WorkerProfileType[];
  days: DayType[];
  month: number;
  year: number;
  calendarData: CalendarType[];
  summaryData?: ShiftPlanSummaryCalendarType[];
  handleOpen?: (data: CalendarType) => void;
  handleHoveredDay?: (day: number | null) => void;
  showHeader: boolean;
  headerProps: {
    searchTerm: string;
    onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    days: DayType[];
    isHolidaysOrWeekendDay: CalendarDayType;
  };
  isResultView?: boolean;
  isHolidayOrWeekendDayMap: CalendarDayType;
  hoveredDay?: number;
  scrollXValue?: number;
}

// Props validation functions
// --------------------------

export const areUnitsEqual = (
  prevUnits: UnitType[],
  nextUnits: UnitType[]
): boolean => {
  if (prevUnits.length !== nextUnits.length) return false;

  return prevUnits.every((prevUnit, index) => {
    const nextUnit = nextUnits[index];
    return (
      prevUnit.id === nextUnit.id &&
      prevUnit.name === nextUnit.name &&
      prevUnit.departmentId === nextUnit.departmentId &&
      prevUnit.shiftPlanId === nextUnit.shiftPlanId &&
      prevUnit.isActive === nextUnit.isActive &&
      prevUnit.freeWeekends === nextUnit.freeWeekends &&
      prevUnit.nightRule === nextUnit.nightRule &&
      prevUnit.checkDayBeforeHoliday === nextUnit.checkDayBeforeHoliday &&
      prevUnit.checkSameAmountOfWeekend === nextUnit.checkSameAmountOfWeekend &&
      isEqual(prevUnit.shiftSpecifications, nextUnit.shiftSpecifications) &&
      isEqual(prevUnit.preferablePatterns, nextUnit.preferablePatterns) &&
      isEqual(prevUnit.notAllowedPatterns, nextUnit.notAllowedPatterns) &&
      isEqual(prevUnit.notPreferablePatterns, nextUnit.notPreferablePatterns) &&
      isEqual(prevUnit.shiftCounts, nextUnit.shiftCounts)
    );
  });
};

export const areUnitUsersEqual = (
  prev: UnitUsers,
  next: UnitUsers
): boolean => {
  // Handle null/undefined
  if (!prev || !next) return prev === next;

  const prevKeys = Object.keys(prev);
  const nextKeys = Object.keys(next);

  // Check if lengths are different
  if (prevKeys.length !== nextKeys.length) return false;

  return prevKeys.every((key) => {
    const prevWorkers = prev[key];
    const nextWorkers = next[key];

    // Check if arrays exist and are arrays
    if (!Array.isArray(prevWorkers) || !Array.isArray(nextWorkers)) {
      return false;
    }

    // Check array lengths
    if (prevWorkers.length !== nextWorkers.length) return false;

    return prevWorkers.every((prevWorker, index) => {
      const nextWorker = nextWorkers[index];

      // Check if worker objects exist
      if (!prevWorker || !nextWorker) return false;

      return (
        prevWorker.id === nextWorker.id &&
        prevWorker.userId === nextWorker.userId &&
        prevWorker.unitId === nextWorker.unitId
      );
    });
  });
};

export const areCalendarDataEqual = (
  prev: CalendarType[],
  next: CalendarType[]
): boolean => {
  if (prev.length !== next.length) return false;

  return prev.every((prevItem, index) => {
    const nextItem = next[index];

    const prevFromTimestamp = convertToISODate(dayjs(prevItem.fromDate));
    const nextFromTimestamp = convertToISODate(dayjs(nextItem.fromDate));
    const prevToTimestamp = convertToISODate(dayjs(prevItem.toDate));
    const nextToTimestamp = convertToISODate(dayjs(nextItem.toDate));

    return (
      prevItem.id === nextItem.id &&
      prevItem.userId === nextItem.userId &&
      prevItem.unitId === nextItem.unitId &&
      prevItem.shiftKey === nextItem.shiftKey &&
      isEqual(prevItem.allowedShiftKeys, nextItem.allowedShiftKeys) &&
      isEqual(prevFromTimestamp, nextFromTimestamp) &&
      isEqual(prevToTimestamp, nextToTimestamp)
    );
  });
};

export const areDaysEqual = (
  prevDays: DayType[],
  nextDays: DayType[]
): boolean => {
  if (prevDays.length !== nextDays.length) return false;

  return prevDays.every(
    (day, index) =>
      day.dayNumber === nextDays[index].dayNumber &&
      day.dayName === nextDays[index].dayName
  );
};

export const areHolidaysEqual = <T extends Record<string | number, boolean>>(
  prevHolidays: T,
  nextHolidays: T
): boolean => {
  const prevEntries = Object.entries(prevHolidays);
  const nextEntries = Object.entries(nextHolidays);

  if (prevEntries.length !== nextEntries.length) return false;

  return prevEntries.every(
    ([key, value]) => nextHolidays[Number(key)] === value
  );
};

export const areShiftConfigEqual = (
  prev: ShiftTypeConfig,
  next: ShiftTypeConfig
): boolean => {
  return (
    prev.icon === next.icon &&
    prev.backGroundColor === next.backGroundColor &&
    prev.iconColor === next.iconColor
  );
};

export const areCalendarEqual = (
  prev: CalendarType,
  next: CalendarType
): boolean => {
  return (
    prev.id === next.id &&
    prev.userId === next.userId &&
    prev.unitId === next.unitId &&
    prev.shiftKey === next.shiftKey &&
    prev.fromDate === next.fromDate &&
    prev.toDate === next.toDate
  );
};

export const areGroupsEqual = (
  prevGroups: GroupType[],
  nextGroups: GroupType[]
): boolean => {
  if (prevGroups.length !== nextGroups.length) return false;

  return prevGroups.every((prevGroup, index) => {
    const nextGroup = nextGroups[index];
    return (
      prevGroup.id === nextGroup.id &&
      prevGroup.name === nextGroup.name &&
      prevGroup.shiftPlanId === nextGroup.shiftPlanId &&
      prevGroup.isActive === nextGroup.isActive &&
      prevGroup.departmentId === nextGroup.departmentId &&
      prevGroup.shiftPlanId === nextGroup.shiftPlanId &&
      prevGroup.shiftCounts === nextGroup.shiftCounts
    );
  });
};

export const areEmptyCellPropsEqual = (
  prevProps: EmptyCellProps,
  nextProps: EmptyCellProps
): boolean => {
  // Check primitive values first
  if (
    prevProps.isCompactView !== nextProps.isCompactView ||
    prevProps.backgroundColor !== nextProps.backgroundColor ||
    prevProps.handleOpen !== nextProps.handleOpen ||
    prevProps.day !== nextProps.day ||
    prevProps.displayDayText !== nextProps.displayDayText
  ) {
    return false;
  }

  // Check if either is undefined
  if (!prevProps.calendarData || !nextProps.calendarData) {
    return prevProps.calendarData === nextProps.calendarData;
  }

  // Compare calendar data
  return (
    prevProps.calendarData.id === nextProps.calendarData.id &&
    prevProps.calendarData.userId === nextProps.calendarData.userId &&
    prevProps.calendarData.unitId === nextProps.calendarData.unitId &&
    prevProps.calendarData.shiftKey === nextProps.calendarData.shiftKey &&
    new Date(prevProps.calendarData.fromDate).getTime() ===
      new Date(nextProps.calendarData.fromDate).getTime() &&
    new Date(prevProps.calendarData.toDate).getTime() ===
      new Date(nextProps.calendarData.toDate).getTime() &&
    prevProps.day === nextProps.day &&
    prevProps.handleHover === nextProps.handleHover
  );
};

export const areShiftPlanSummaryCalendarTypeEqual = (
  prev: ShiftPlanSummaryCalendarType[],
  next: ShiftPlanSummaryCalendarType[]
): boolean => {
  // Handle null/undefined arrays
  if (!prev || !next) return prev === next;

  // Check lengths
  if (prev.length !== next.length) return false;

  return prev.every((prevItem: ShiftPlanSummaryCalendarType, index) => {
    const nextItem = next[index];

    // Check if either item is undefined
    if (!prevItem || !nextItem) return prevItem === nextItem;

    // Check if sumShifts arrays exist
    if (!prevItem.sumShifts || !nextItem.sumShifts) {
      return prevItem.sumShifts === nextItem.sumShifts;
    }

    return (
      prevItem.userId === nextItem.userId &&
      prevItem.sumHours === nextItem.sumHours &&
      prevItem.sumShifts.length === nextItem.sumShifts.length &&
      prevItem.sumShifts.every((prevShift, shiftIndex) => {
        const nextShift = nextItem.sumShifts[shiftIndex];

        // Check if shift object exists
        if (!prevShift || !nextShift) return prevShift === nextShift;

        return (
          prevShift.shiftKey === nextShift.shiftKey &&
          prevShift.duration === nextShift.duration &&
          prevShift.sumShifts === nextShift.sumShifts
        );
      })
    );
  });
};

export const arePropsEqualShiftPlanCalendar = (
  prevProps: ShiftPlanCalendarProps,
  nextProps: ShiftPlanCalendarProps
): boolean => {
  // Complex object checks using specialized functions
  return (
    areUnitUsersEqual(prevProps.users, nextProps.users) &&
    areUnitsEqual(prevProps.units, nextProps.units) &&
    areCalendarDataEqual(prevProps.calendarData, nextProps.calendarData) &&
    isEqual(prevProps.availableKeys, nextProps.availableKeys) &&
    areShiftPlanSummaryCalendarTypeEqual(
      prevProps.summaryData,
      nextProps.summaryData
    ) &&
    prevProps.isResultView === nextProps.isResultView &&
    prevProps.addEditManuallyRequest === nextProps.addEditManuallyRequest &&
    prevProps.month === nextProps.month &&
    prevProps.year === nextProps.year
  );
};

export const areShiftPlanCalendarTableHeaderPropsEqual = (
  prev: ShiftPlanCalendarTableHeaderProps,
  next: ShiftPlanCalendarTableHeaderProps
): boolean => {
  return (
    prev.searchTerm === next.searchTerm &&
    prev.onSearch === next.onSearch &&
    areDaysEqual(prev.days, next.days) &&
    areHolidaysEqual(
      prev.isHolidaysOrWeekendDay,
      next.isHolidaysOrWeekendDay
    ) &&
    prev.hoveredDay === next.hoveredDay &&
    prev.isSticky === next.isSticky &&
    prev.scrollXValue === next.scrollXValue
  );
};

export const areWorkerProfileEqual = (
  prev: WorkerProfileType,
  next: WorkerProfileType
): boolean => {
  return (
    prev.id === next.id &&
    prev.userId === next.userId &&
    prev.unitId === next.unitId &&
    prev.departmentId === next.departmentId &&
    prev.baseWorkHours === next.baseWorkHours &&
    prev.jobDescription === next.jobDescription &&
    prev.canWorkAlone === next.canWorkAlone &&
    prev.canWorkAtWeekend === next.canWorkAtWeekend &&
    prev.contractPercentage === next.contractPercentage &&
    prev.created === next.created &&
    prev.mentorId === next.mentorId &&
    prev.paidPublicHolidays === next.paidPublicHolidays &&
    prev.shiftRestrictions === next.shiftRestrictions &&
    prev.spUnitId === next.spUnitId &&
    prev.updated === next.updated &&
    isEqual(prev.user, next.user) &&
    prev.workerProfileType === next.workerProfileType
  );
};

export const arePropsShiftPlanUserRowEqual = (
  prevProps: ShiftPlanCalendarUserRowProps,
  nextProps: ShiftPlanCalendarUserRowProps
): boolean => {
  const primitivePropsEqual = ["departmentId", "isResultView"].every(
    (key) =>
      prevProps[key as keyof ShiftPlanCalendarUserRowProps] ===
      nextProps[key as keyof ShiftPlanCalendarUserRowProps]
  );

  if (!primitivePropsEqual) return false;

  if (
    prevProps.workerProfile.id !== nextProps.workerProfile.id ||
    prevProps.unitId !== nextProps.unitId ||
    prevProps.isHoveredWorkerProfile !== nextProps.isHoveredWorkerProfile ||
    prevProps.userSummaryData !== nextProps.userSummaryData ||
    !areDaysEqual(prevProps.days, nextProps.days) ||
    !areCalendarDataEqual(
      prevProps.userCalendarData,
      nextProps.userCalendarData
    ) ||
    !areHolidaysEqual(
      prevProps.isHolidayOrWeekendDayMap,
      nextProps.isHolidayOrWeekendDayMap
    ) ||
    prevProps.handleOpen !== nextProps.handleOpen ||
    prevProps.handleHover !== nextProps.handleHover ||
    prevProps.month !== nextProps.month ||
    prevProps.year !== nextProps.year ||
    !areWorkerProfileEqual(prevProps.workerProfile, nextProps.workerProfile) ||
    !areShiftPlanSummaryCalendarTypeEqual(
      [prevProps.userSummaryData],
      [nextProps.userSummaryData]
    )
  ) {
    return false;
  }

  return true;
};

export const arePropsShiftContentCellEqual = (
  prevProps: ShiftContentProps,
  nextProps: ShiftContentProps
): boolean => {
  // Compare primitive props first (fastest checks)
  if (
    prevProps.isCompactView !== nextProps.isCompactView ||
    prevProps.isSpecialResultView !== nextProps.isSpecialResultView ||
    prevProps.isResultView !== nextProps.isResultView ||
    prevProps.handleOpen !== nextProps.handleOpen ||
    prevProps.unitId !== nextProps.unitId ||
    prevProps.handleHover !== nextProps.handleHover ||
    prevProps.hoveredDay !== nextProps.hoveredDay ||
    prevProps.shiftConfig.icon !== nextProps.shiftConfig.icon ||
    prevProps.shiftConfig.backGroundColor !==
      nextProps.shiftConfig.backGroundColor ||
    prevProps.shiftConfig.iconColor !== nextProps.shiftConfig.iconColor ||
    prevProps.calendarData.shiftKey !== nextProps.calendarData.shiftKey ||
    prevProps.calendarData.userId !== nextProps.calendarData.userId ||
    prevProps.calendarData.unitId !== nextProps.calendarData.unitId ||
    prevProps.calendarData.id !== nextProps.calendarData.id ||
    prevProps.calendarData.fromDate !== nextProps.calendarData.fromDate ||
    prevProps.calendarData.toDate !== nextProps.calendarData.toDate
  ) {
    return false;
  }

  // Compare shiftConfig object
  if (!areShiftConfigEqual(prevProps.shiftConfig, nextProps.shiftConfig)) {
    return false;
  }

  // Compare calendar data
  if (!areCalendarEqual(prevProps.calendarData, nextProps.calendarData)) {
    return false;
  }

  return true;
};

export const arePropsShiftPlanUnitTableEqual = (
  prev: ShiftPlanUnitTableProps,
  next: ShiftPlanUnitTableProps
) =>
  prev.unit.id === next.unit.id &&
  isEqual(prev.days, next.days) &&
  isEqual(prev.calendarData, next.calendarData) &&
  isEqual(prev.workerProfiles, next.workerProfiles) &&
  prev.showHeader === next.showHeader &&
  isEqual(prev.headerProps, next.headerProps) &&
  isEqual(prev.isResultView, next.isResultView) &&
  isEqual(prev.isHolidayOrWeekendDayMap, next.isHolidayOrWeekendDayMap) &&
  isEqual(prev.hoveredDay, next.hoveredDay) &&
  prev.handleOpen === next.handleOpen &&
  prev.handleHoveredDay === next.handleHoveredDay &&
  prev.scrollXValue === next.scrollXValue &&
  prev.summaryData === next.summaryData;
